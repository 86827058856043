import React from "react";
import { Modal } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";

const ModalChangeAccount = ({ show, handleClose }) => {
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body className="py-4">
        <h4 className="siap-bold-txt text-center">Ubah Jenis Akun</h4>
        <div className="text-center change-account-subhead">
          Pilih metode SIAP yang Anda inginkan
        </div>
        <div className="d-flex justify-content-around px-5 mt-4">
          <div style={{ flex: 1 }} className="mr-2">
            <div>
              <div className="change-account-menu active">Borrower</div>
              <div className="change-account-current mt-3">
                <FaCheckCircle className="mr-2" color="1CDD89" />{" "}
                <span>Akun Saat ini</span>
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }} className="ml-2">
            <div style={{ flex: 1 }} className="mr-2">
              <div>
                <div className="change-account-menu">Lender</div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalChangeAccount;
