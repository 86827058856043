import React from "react";
import NavBar from "components/NavBar";
import Disclaimer from "components/Disclaimer";
import CarouselComponent from "components/CarouselComponent";
import ProdukLandingPage from "components/ProdukLandingPage";
import SiapInvestasi from "components/SiapInvestasi";
import DanaTunai from "components/DanaTunai";
import Foo from "components/FooterContent";
import DanaHaji from "components/DanaHaji";
import DanaMobil from "components/DanaMobil";
import DanaRumah from "components/DanaRumah";

const ListProdukPage = () => {
  return (
    <>
      <NavBar />

      <ProdukLandingPage />

      {/* <SiapInvestasi />

      <DanaTunai /> */}
      <DanaHaji />
      <DanaMobil />
      <DanaRumah />
      <Disclaimer />
      <Foo />
    </>
  );
};

export default ListProdukPage;
