import React, { useState, useContext } from "react";
import {
  ListGroup,
  Nav,
  Col,
  Tab,
  Row,
  Dropdown,
  Button,
} from "react-bootstrap";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { ProjectContext } from "../context/ProjectContext";
import { IoIosArrowDown } from "react-icons/io";
import {
  IoLockClosedOutline,
  IoLockClosed,
  IoSearchOutline,
  IoSearch,
} from "react-icons/io5";
import {
  HiHome,
  HiOutlineHome,
  HiNewspaper,
  HiOutlineNewspaper,
} from "react-icons/hi";

import Notif from "assets/icons/iconly-two-tone-notification.svg";
import Logo from "assets/icons/logo-color.png";
import Logo2 from "assets/icons/logo-color@2x.png";
import Logo3 from "assets/icons/logo-color@3x.png";
import ModalLoading from "./ModalLoading";
import ModalChangeAccount from "./ModalChangeAccount";

import { FaRegUser, FaUserAlt } from "react-icons/fa";

const SideBar = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  let { pathname } = location;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  return (
    <>
      <ModalLoading
        show={state.modalLoading}
        handleClose={() =>
          dispatch({
            type: "SET_LOADING",
            payload: false,
          })
        }
      />

      <ModalChangeAccount
        show={state.modalAccount}
        handleClose={() =>
          dispatch({
            type: "SET_MODAL_ACCOUNT",
            payload: false,
          })
        }
      />

      {/* Top Navigation Mobile */}
      <div className="sidenav-mobile">
        <div
        // onClick={() =>
        //   dispatch({
        //     type: "SET_MODAL_ACCOUNT",
        //     payload: true,
        //   })
        // }
        >
          <img
            src={Logo}
            alt="logo-motion-credit"
            srcSet={`${Logo2} 866w, ${Logo3} 1314w`}
            className="logosidnav-mobile"
            onClick={() => history.push("/home")}
          />
          {/* <div className="sidebar-mobile-type">
            Borrower <IoIosArrowDown />
          </div> */}
        </div>
        <div className="d-flex">
          <img
            src={Notif}
            alt="notif-icon"
            className="notif-icon-mobile"
            onClick={() =>
              dispatch({
                type: "SET_MODAL_NOTIFICATION",
                payload: true,
              })
            }
          />
        </div>
      </div>

      {/* Bottom Navigation Mobile */}
      {(pathname === "/home" ||
        pathname === "/pengajuan" ||
        pathname === "/bantuan" ||
        pathname === "/account") && (
        <div className="bottom-nav-mobile">
          <div
            className="bottom-nav-menu"
            onClick={() => history.push("/home")}
          >
            {pathname === "/home" ? (
              <HiHome size={25} />
            ) : (
              <HiOutlineHome size={25} />
            )}

            <div className="mt-1">Home</div>
          </div>
          <div
            className="bottom-nav-menu"
            onClick={() => history.push("/pengajuan")}
          >
            {pathname === "/pengajuan" ? (
              <HiNewspaper size={25} />
            ) : (
              <HiOutlineNewspaper size={25} />
            )}
            <div className="mt-1">Pengajuan</div>
          </div>
          <div
            className="bottom-nav-menu"
            onClick={() => history.push("/bantuan")}
          >
            {pathname === "/bantuan" ? (
              <IoSearch size={25} />
            ) : (
              <IoSearchOutline size={25} />
            )}
            <div className="mt-1">Bantuan</div>
          </div>
          <div
            className="bottom-nav-menu"
            onClick={() => history.push("/account")}
          >
            {pathname === "/account" ? (
              <FaUserAlt size={24} />
            ) : (
              <FaRegUser FaUserAlt size={24} />
            )}
            <div className="mt-1">Akun</div>
          </div>
        </div>
      )}

      <Nav className="flex-column justify-content-between h-100 left-navbar-container">
        <div className="row d-flex justify-content-center">
          <img
            src={Logo}
            alt="logo-motion-credit"
            srcSet={`${Logo2} 866w, ${Logo3} 1314w`}
            className="logosidnav cursor-pointer"
            onClick={() => history.push("/home")}
          />
        </div>

        <div
          className="left-navbar-type"
          onClick={() =>
            dispatch({
              type: "SET_MODAL_ACCOUNT",
              payload: true,
            })
          }
        >
          <div>
            <div className="account-type-txt">Jenis Akun</div>
            <div className="account-active-txt">Borower</div>
          </div>
          <IoIosArrowDown className="arrow-down" />
        </div>

        <div className="row mt-4 mb-4 w-100">
          <div className="col-12">
            <div
              className={`row align-items-center sidebar-menu ${
                (pathname === "/home" ||
                  pathname === "/landing-page-mobil" ||
                  pathname === "/motion-dana-mobil-form" ||
                  pathname === "/hasil-simulasi-dana-mobil" ||
                  pathname === `/daftar-dana-mobil/${id}` ||
                  pathname === `/syarat-ketentuan-mobil/${id}` ||
                  pathname === "/landing-page-rumah" ||
                  pathname === "/motion-dana-rumah-form" ||
                  pathname === "/hasil-simulasi-rumah" ||
                  pathname === `/daftar-dana-rumah/${id}` ||
                  pathname === `/syarat-ketentuan-rumah/${id}` ||
                  pathname === "/motion-dana-haji" ||
                  pathname === "/motion-dana-haji-form" ||
                  pathname === "/motion-dana-haji-form-2" ||
                  pathname === "/motion-dana-haji-form-3" ||
                  pathname === `/daftar-dana-haji/${id}` ||
                  pathname === `/motion-dana-haji-rekap/${id}`) &&
                "active"
              }`}
              onClick={() => history.push(`/home`)}
            >
              <div className="col-sm-2">
                {/* <img src={Home} alt="home-icon" /> */}
                {pathname === "/home" ||
                pathname === "/landing-page-mobil" ||
                pathname === "/motion-dana-mobil-form" ||
                pathname === "/hasil-simulasi-dana-mobil" ||
                pathname === `/daftar-dana-mobil/${id}` ||
                pathname === `/syarat-ketentuan-mobil/${id}` ||
                pathname === "/landing-page-rumah" ||
                pathname === "/motion-dana-rumah-form" ||
                pathname === "/hasil-simulasi-rumah" ||
                pathname === `/daftar-dana-rumah/${id}` ||
                pathname === `/syarat-ketentuan-rumah/${id}` ||
                pathname === "/motion-dana-haji" ||
                pathname === "/motion-dana-haji-form" ||
                pathname === "/motion-dana-haji-form-2" ||
                pathname === "/motion-dana-haji-form-3" ||
                pathname === `/daftar-dana-haji/${id}` ||
                pathname === `/motion-dana-haji-rekap/${id}` ? (
                  <HiHome size={25} />
                ) : (
                  <HiOutlineHome size={25} />
                )}
              </div>
              <div className="col-sm-10 font-weight-bold">Home</div>
            </div>

            <div
              className={`row align-items-center sidebar-menu ${
                (pathname === "/pengajuan" ||
                  pathname === `/pengajuandanamobil/${id}` ||
                  pathname === `/pengajuandanamobil/${id}/track-dana-mobil` ||
                  pathname === `/datadirimobil/${id}` ||
                  pathname === `/dokumen-mobil/${id}` ||
                  pathname === `/jadwal-mobil/${id}` ||
                  pathname === `/pengajuandanarumah/${id}` ||
                  pathname === `/pengajuandanahaji/${id}` ||
                  pathname === `/datadirihaji/${id}` ||
                  pathname === `/dokumenhaji` ||
                  pathname === `/jadwalhaji/${id}` ||
                  pathname === `/datadirirumah/${id}` ||
                  pathname === `/jadwalrumah/${id}` ||
                  pathname === `/dokumenrumah/${id}` ||
                  pathname === `/dokumen-sign-rumah/${id}` ||
                  pathname === `/dokumen-customer-rumah/${id}`) &&
                "active"
              }`}
              onClick={() => history.push(`/pengajuan`)}
            >
              <div className="col-sm-2">
                {/* <img src={Lock} alt="home-icon" /> */}
                {pathname === "/pengajuan" ||
                pathname === `/pengajuandanamobil/${id}` ||
                pathname === `/pengajuandanamobil/${id}/track-dana-mobil` ||
                pathname === `/datadirimobil/${id}` ||
                pathname === `/dokumen-mobil/${id}` ||
                pathname === `/jadwal-mobil/${id}` ||
                pathname === `/pengajuandanarumah/${id}` ||
                pathname === `/pengajuandanahaji/${id}` ||
                pathname === `/datadirihaji/${id}` ||
                pathname === `/dokumenhaji` ||
                pathname === `/jadwalhaji/${id}` ||
                pathname === `/datadirirumah/${id}` ||
                pathname === `/jadwalrumah/${id}` ||
                pathname === `/dokumenrumah/${id}` ||
                pathname === `/dokumen-sign-rumah/${id}` ||
                pathname === `/dokumen-customer-rumah/${id}` ? (
                  <IoLockClosed size={25} />
                ) : (
                  <IoLockClosedOutline size={25} />
                )}
              </div>
              <div className="col-sm-10 font-weight-bold">Pengajuan</div>
            </div>

            <div
              className={`row align-items-center sidebar-menu ${
                pathname === "/bantuan" && "active"
              }`}
              onClick={() => history.push(`/bantuan`)}
            >
              <div className="col-sm-2">
                {/* <img src={Search} alt="home-icon" /> */}
                {pathname === "/bantuan" ? (
                  <IoSearch size={25} />
                ) : (
                  <IoSearchOutline size={25} />
                )}
              </div>
              <div className="col-sm-10 font-weight-bold">Bantuan</div>
            </div>
          </div>
        </div>

        <div className="row mt-4 w-100">
          <Button
            variant="primary"
            active
            block
            style={{
              width: "100%",
              height: "55px",
              backgroundColor: "#0c135f",
              borderRadius: "0px",
              fontSize: "14px",
            }}
            className="sign-up-btn"
            onClick={() => console.log("click")}
          >
            Daftar jadi lender
          </Button>
        </div>
        <div className="row w-100 d-flex justify-content-center align-items-center mt-5 tnc-text">
          <div
            className="mb-2 hover-bold"
            onClick={() => history.push(`/syarat-ketentuan`)}
          >
            Syarat & ketentuan
          </div>
          <div
            className="hover-bold"
            onClick={() => history.push(`/kebijakan`)}
          >
            Kebijakan privasi
          </div>
        </div>
      </Nav>
    </>
  );
};

export default SideBar;
