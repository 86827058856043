import React from "react";
import NavBar from "components/NavBar";
import Foo from "components/FooterContent";
import Benefit from "components/Benefit2";

import SiapHaji from "assets/icons/icon-product-dana-haji-big.png";
import SiapHaji2 from "assets/icons/icon-product-dana-haji-big@2x.png";
import SiapHaji3 from "assets/icons/icon-product-dana-haji-big@3x.png";
import Dapatkan from "components/DapatkanPendanaan";
import InformasiDanaHaji from "components/InformasiDanaHaji";
import TestimoniLandingPage from "components/TestimoniLandingPage";
import Disclaimer from "components/Disclaimer";

const ProdukHajiPage = () => {
  return (
    <>
      <NavBar />

      <div className="container section-home">
        <div className="row">
          <div className="col-md-6">
            <div className="landing-head-orange">Produk</div>
            <div className="landing-head-desc">
              Segerakan ke tanah suci bersama MNC Dana Haji
            </div>
            <p className="landing-desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et
              hendrerit quam. Nunc id volutpat libero. Aliquam erat volutpat.
              Nunc quis consequat massa. Proin semper elit turpis, non
              scelerisque nulla dictum in. Proin sit amet mauris ac lectus
              semper feugiat nec non odio. Curabitur sollicitudin justo ac lorem
              iaculis, nec pellentesque libero interdum.
            </p>

            <button className="landing-btn">Daftar Segera</button>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <img
              src={SiapHaji}
              alt="logo-haji"
              srcSet={`${SiapHaji2} 866w, ${SiapHaji3} 1314w`}
              style={{ width: "450px", height: "450px" }}
            />
          </div>
        </div>
      </div>

      <Benefit />
      <InformasiDanaHaji />
      <TestimoniLandingPage />
      <Dapatkan />
      <Disclaimer />
      <Foo />
    </>
  );
};

export default ProdukHajiPage;
