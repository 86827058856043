import React, { useContext } from "react";
import { ProjectContext } from "context/ProjectContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import Flag from "../assets/icons/icon-language.svg";
import LogoColor from "assets/images/logo_motion.png";
import LogoOjk from "assets/images/OJK_Logo.png";
import { FiMenu } from "react-icons/fi";

import AsideHome from "./AsideHome";

const NavBar = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const handleCollapsed = (value) => {
    dispatch({
      type: "SET_COLLAPSED",
      payload: value,
    });
  };

  const openCollapsed = () => {
    dispatch({
      type: "SET_COLLAPSED",
      payload: false,
    });
  };

  const closeCollapsed = () => {
    dispatch({
      type: "SET_COLLAPSED",
      payload: true,
    });
  };

  return (
    <>
      <div className={state.collapsed ? "" : "dim"} onClick={closeCollapsed} />
      <AsideHome
        collapsed={state.collapsed}
        handleCollapsed={handleCollapsed}
      />

      <div className="container-fluid top-home-nav">
        <div className="row top-navbar-container px-4">
          <div className="col-6">
            <img src={LogoOjk} alt="ojk" className="logo-ojk" />
          </div>
        </div>
      </div>

      <div className="container-fluid top-navbar-2-container top-home-nav">
        <div className="row top-navbar-2 px-4">
          <div className="col-3 d-flex align-items-center">
            <img
              src={LogoColor}
              alt="Logo"
              className="icon-color cursor-pointer"
              onClick={() => history.push("/")}
            />
          </div>
          <div className="col-6">
            <div className="row d-flex justify-content-center h-100">
              <div
                className={`col-2 top-navbar-menu ${
                  pathname === "/" && "active"
                }`}
                onClick={() => history.push(`/`)}
              >
                Beranda
              </div>
              <div
                className={`col-2 top-navbar-menu ${
                  pathname === "/list-produk" && "active"
                }`}
                onClick={() => history.push(`/list-produk`)}
              >
                Produk
              </div>
              <div
                className={`col-2 top-navbar-menu ${
                  pathname === "/informasi" && "active"
                }`}
                onClick={() => history.push(`/informasi`)}
              >
                Informasi
              </div>
              <div
                className={`col-3 top-navbar-menu ${
                  pathname === "/tentang-kami" && "active"
                }`}
                onClick={() => history.push(`/tentang-kami`)}
              >
                Tentang Kami
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid top-navbar-mobile-container">
        <div className="row h-100">
          <div className="col-6 d-flex align-items-center">
            <img src={LogoColor} alt="Logo" className="icon-color-mobile" />
          </div>
          <div className="col-6 d-flex align-items-center justify-content-end">
            <FiMenu
              size={28}
              color="#0c135f"
              className="cursor-pointer"
              onClick={() => openCollapsed()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
