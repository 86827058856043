import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Icon from "assets/images/search.png";
import { Form, Button, Modal, Accordion, Card } from "react-bootstrap";
import Investasi from "assets/icons/icon-product-investasi.png";
import Investasi2 from "assets/icons/icon-product-investasi@2x.png";
import Investasi3 from "assets/icons/icon-product-investasi@3x.png";
import Dana from "assets/images/dana_tunai_3x.png";
import Mobil from "assets/icons/icon-product-mobil.png";
import Mobil2 from "assets/icons/icon-product-mobil@2x.png";
import Mobil3 from "assets/icons/icon-product-mobil@3x.png";
import SiapHaji from "assets/icons/icon-product-dana-haji.png";
import SiapHaji2 from "assets/icons/icon-product-dana-haji@2x.png";
import SiapHaji3 from "assets/icons/icon-product-dana-haji@3x.png";
import SiapRumah from "assets/icons/icon-product-rumah.png";
import SiapRumah2 from "assets/icons/icon-product-rumah@2x.png";
import SiapRumah3 from "assets/icons/icon-product-rumah@3x.png";
import DanaTunai from "assets/icons/icon-product-dana-tunai.png";
import DanaTunai2 from "assets/icons/icon-product-dana-tunai@2x.png";
import DanaTunai3 from "assets/icons/icon-product-dana-tunai@3x.png";
import { FaChevronDown } from "react-icons/fa";

import { API } from "config/api";

import ModalQuestionAnswer from "./ModalQuestionAnswer";

const ProdukSiap = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [faqs, setFaqs] = useState(null);

  const handleOpen = async (type) => {
    try {
      setShow(true);
      const res = await API.get(`/references/faqs?label=${type}`);
      if (res.data.success) {
        setFaqs(res.data.data);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <ModalQuestionAnswer show={show} handleClose={handleClose} faqs={faqs} />
      <div className="produk-card-container">
        <div className="row">
          {/* <div className="home-right-menu col-md-4 mb-4 d-flex flex-column align-items-center justify-content-center cursor-pointer">
            <img
              src={Investasi}
              alt="logo-investasi"
              srcSet={`${Investasi2} 866w, ${Investasi3} 1314w`}
              // className="produk-card-img"
            />
            <div className="home-right-txt">Motion Credit Investasi</div>
          </div> */}

          {/* <div className="home-right-menu col-md-4 mb-4 text-center flex-column align-items-center justify-content-center cursor-pointer">
            <img
              src={DanaTunai}
              alt="logo-dana-tunai"
              srcSet={`${DanaTunai2} 866w, ${DanaTunai3} 1314w`}
              // className="produk-card-img"
            />
            <div className="home-right-txt">Motion Credit Dana Tunai</div>
          </div> */}

          <div
            className="home-right-menu col-md-4 mb-4 text-center flex-column align-items-center justify-content-center cursor-pointer"
            onClick={() => handleOpen("danamobil")}
          >
            <img
              src={Mobil}
              alt="logo-mobil"
              srcSet={`${Mobil2} 866w, ${Mobil3} 1314w`}
              // className="produk-card-img"
            />
            <div className="home-right-txt">
              Motion Credit <br /> Dana Mobil
            </div>
          </div>
          <div
            className="home-right-menu col-md-4 mb-4 text-center flex-column align-items-center justify-content-center cursor-pointer"
            onClick={() => handleOpen("danarumah")}
          >
            <img
              src={SiapRumah}
              alt="logo-mobil"
              srcSet={`${SiapRumah2} 866w, ${SiapRumah3} 1314w`}
              // className="produk-card-img"
            />
            <div className="home-right-txt">
              Motion Credit <br /> Dana Rumah
            </div>
          </div>

          <div
            className="home-right-menu col-md-4 mb-4 text-center flex-column align-items-center justify-content-center cursor-pointer"
            onClick={() => handleOpen("danahaji")}
          >
            <img
              src={SiapHaji}
              alt="logo-motion-haji"
              srcSet={`${SiapHaji2} 866w, ${SiapHaji3} 1314w`}
              // className="produk-card-img"
            />
            <div className="home-right-txt">
              Motion Credit <br /> Dana Haji
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div
            className="home-right-menu col-md-4 mb-4 text-center flex-column align-items-center justify-content-center cursor-pointer"
            onClick={() => history.push(`/landing-page-mobil`)}
          >
            <img
              src={Mobil}
              alt="logo-mobil"
              srcSet={`${Mobil2} 866w, ${Mobil3} 1314w`}
              // className="produk-card-img"
            />
            <div className="home-right-txt">Motion Credit Dana Mobil</div>
          </div>
          <div
            className="home-right-menu col-md-4 mb-4 text-center flex-column align-items-center justify-content-center cursor-pointer"
            onClick={() => history.push(`/landing-page-rumah`)}
          >
            <img
              src={SiapRumah}
              alt="logo-mobil"
              srcSet={`${SiapRumah2} 866w, ${SiapRumah3} 1314w`}
              // className="produk-card-img"
            />
            <div className="home-right-txt">Motion Credit Dana Rumah</div>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default ProdukSiap;
