import React from "react";
import { Card } from "react-bootstrap";
import Online from "assets/images/tumbnail-image-online.png";
import Mudah from "assets/icons/tumbnail-syarat.webp";
import Mudah2 from "assets/icons/tumbnail-syarat@2x.webp";
import Mudah3 from "assets/icons/tumbnail-syarat@3x.webp";
import Pasti from "assets/images/pelunasaan_3x.png";
import SiapHaji from "assets/icons/icon-product-dana-haji-big.png";
import SiapHaji2 from "assets/icons/icon-product-dana-haji-big@2x.png";
import SiapHaji3 from "assets/icons/icon-product-dana-haji-big@3x.png";

import Lunas from "assets/icons/icon-product-pelunasaan-big.png";
import Lunas2 from "assets/icons/icon-product-pelunasaan-big@2x.png";
import Lunas3 from "assets/icons/icon-product-pelunasaan-big@3x.png";

const Benefit = () => {
  return (
    <div className="container-fluid bg-blue">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="landing-head-orange">Keuntungan</div>
            <div className="landing-head-desc">
              Hanya di Motion Credit Anda mendapatkan
            </div>

            <div className="row mt-4">
              <div className="col-md-4 my-3">
                <Card
                  style={{
                    width: "100%",
                    height: "500px",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    boxShadow: "0 20px 40px 0 #bedcef",
                  }}
                >
                  <Card.Body>
                    <div className="row d-flex justify-content-center mt-3">
                      <img
                        src={Mudah}
                        alt="logo-Mudah"
                        srcSet={`${Mudah2} 866w, ${Mudah3} 1314w`}
                        style={{
                          width: "200px",
                          height: "200px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>

                    <Card.Title
                      style={{
                        textAlign: "center",
                        marginTop: "34px",
                        marginBottom: "17px",
                      }}
                      className="siap-bold-txt"
                    >
                      Mudah
                    </Card.Title>

                    <Card.Text
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        color: "#3a3a3a",
                      }}
                    >
                      Pembiayaan berbasis syariah dengan proses mudah, cepat,
                      dan aman
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-md-4 my-3">
                <Card
                  style={{
                    width: "100%",
                    height: "500px",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    boxShadow: "0 20px 40px 0 #bedcef",
                  }}
                >
                  <Card.Body>
                    <div className="row d-flex justify-content-center mt-3">
                      <img
                        src={Lunas}
                        alt="logo-lunas"
                        srcSet={`${Lunas2} 866w, ${Lunas3} 1314w`}
                        style={{
                          width: "200px",
                          height: "200px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>

                    <Card.Title
                      style={{
                        textAlign: "center",
                        marginTop: "34px",
                        marginBottom: "17px",
                      }}
                      className="siap-bold-txt"
                    >
                      Pasti
                    </Card.Title>

                    <Card.Text
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        color: "#3a3a3a",
                      }}
                    >
                      Kepastian mendapat nomor porsi haji
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-md-4 my-3">
                <Card
                  style={{
                    width: "100%",
                    height: "500px",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    boxShadow: "0 20px 40px 0 #bedcef",
                  }}
                >
                  <Card.Body>
                    <div className="row d-flex justify-content-center mt-3">
                      <img
                        src={SiapHaji}
                        alt="logo-haji"
                        srcSet={`${SiapHaji2} 866w, ${SiapHaji3} 1314w`}
                        style={{
                          width: "200px",
                          height: "200px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>

                    <Card.Title
                      style={{
                        textAlign: "center",
                        marginTop: "34px",
                        marginBottom: "17px",
                      }}
                      className="siap-bold-txt"
                    >
                      Berkah
                    </Card.Title>

                    <Card.Text
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        color: "#3a3a3a",
                      }}
                    >
                      Pembiayaan murni berbasis syariah
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Benefit;
