import axios from "axios";
import moment from "moment";

// Set config defaults when creating the instance || Base URL
export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: process.env.REACT_APP_BASE_URL,
});

export const APIPUBLIC = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: process.env.REACT_APP_BASE_URL,
});

let refresh = false;

API.interceptors.request.use(
  async (req) => {
    const token = JSON.parse(localStorage.getItem("token"));
    refresh = true;
    const { expires_at, refresh_token } = token;
    const convertUnix = moment.unix(expires_at);

    console.log("expired at:", moment(convertUnix).format("LLL"));

    let accessToken = token.access_token;

    if (moment(new Date().toISOString()).isAfter(convertUnix)) {
      console.log("Expired token");

      accessToken = await getRefreshToken(refresh_token);
      req.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      req.headers.Authorization = `Bearer ${accessToken}`;
      console.log("Token not expired");
    }

    refresh = false;

    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

const getRefreshToken = async (refreshToken) => {
  try {
    const payload = {
      refresh_token: refreshToken,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/token/refresh`,
      payload
    );

    localStorage.setItem("token", JSON.stringify(res.data.data));

    console.log("get new token");

    return res.data.data.access_token;
  } catch (error) {
    console.log(error);
    window.location.href = "/login";
  }
};

// Alter defaults after instance has been created || Integrate default header for auth
// export const setAuthToken = (token) => {
//   if (token) {
//     API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   } else {
//     delete API.defaults.headers.common["Authorization"];
//   }
// };
