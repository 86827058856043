import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Switch from "react-switch";

const ModalNotificationSettings = ({ show, handleClose }) => {
  const [checked, setChecked] = useState(false);
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body className="p-4">
        <h4 className="siap-bold-txt">Pengaturan Notifikasi</h4>
        <div className="notif-setting-subhead">
          Pengaturan untuk notifikasi yang terkait SIAP apps
        </div>

        <div className="notif-setting-top-menu mt-4">
          <div>
            <div className="siap-bold-txt notif-setting-head">
              Notifikasi Aplikasi
            </div>
            <label htmlFor="notif" className="notif-setting-subhead">
              Pilih dan Aktifkan Notifikasi SIAP
            </label>
          </div>
          <Switch
            id="notif"
            onColor="#0c135f"
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={() => setChecked(!checked)}
            checked={checked}
          />
        </div>

        <div className="siap-bold-txt notif-setting-head mt-4">
          Detail Pemberitahuan
        </div>

        <div className="notif-setting-switch mt-3">
          <label
            htmlFor="promotion"
            className="notif-setting-subhead cursor-pointer"
          >
            Promosi dan Penawaran
          </label>
          <input
            type="checkbox"
            id="promotion"
            className="cursor-pointer mr-3"
          />
        </div>

        <div className="notif-setting-switch mt-3">
          <label
            htmlFor="news"
            className="notif-setting-subhead cursor-pointer"
          >
            Berita dan Informasi Layanan
          </label>
          <input type="checkbox" id="news" className="cursor-pointer mr-3" />
        </div>

        <div className="notif-setting-switch mt-3">
          <label
            htmlFor="status"
            className="notif-setting-subhead cursor-pointer"
          >
            Proses Status Pinjaman
          </label>
          <input type="checkbox" id="status" className="cursor-pointer mr-3" />
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-md-5">
            <Button className="notif-setting-btn">Simpan</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalNotificationSettings;
