import React from "react";
import Iphone from "assets/icons/phone-mnc.png";
import Iphone2 from "assets/icons/phone-mnc@2x.png";
import Iphone3 from "assets/icons/phone-mnc@3x.png";

const TutorialLandingPage = () => {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-md-6 py-4">
          <div className="landing-head-orange">Tutorial</div>
          <div className="landing-head-desc mb-5">Semudah 1, 2, 3, 4!</div>

          <div className="row tutorial-menu active mt-3">
            <div className="col-2 tutorial-number">01</div>
            <div className="col-10 tutorial-right">
              Daftar dan Masuk Aplikasi
            </div>
          </div>

          <div className="row tutorial-menu mt-3">
            <div className="col-2 tutorial-number">02</div>
            <div className="col-10 tutorial-right">
              Pilih Produk Pembiayaan dan Isi Form
            </div>
          </div>

          <div className="row tutorial-menu mt-3">
            <div className="col-2 tutorial-number">03</div>
            <div className="col-10 tutorial-right">
              Pengajuan Diterima dan Diproses
            </div>
          </div>

          <div className="row tutorial-menu mt-3">
            <div className="col-2 tutorial-number">04</div>
            <div className="col-10 tutorial-right">
              Pengajuan Disetujui, Dana Siap Digunakan
            </div>
          </div>
        </div>
        <div className="col-md-6 py-4 d-flex justify-content-center align-items-center">
          <img
            src={Iphone}
            alt="phone-image"
            srcSet={`${Iphone2} 866w, ${Iphone3} 1314w`}
            className="tutorial-phone"
          />
        </div>
      </div>
    </div>
  );
};

export default TutorialLandingPage;
