import React from "react";
import { Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const CarouselComponent = () => {
  const navigate = useHistory();
  const Banner1 = "https://storage.googleapis.com/bucket-motioncredit-assets/banner-1.jpg"
  const Banner2 = "https://storage.googleapis.com/bucket-motioncredit-assets/banner-2.jpg"
  const Banner3 = "https://storage.googleapis.com/bucket-motioncredit-assets/banner-3.jpg"
  const WebBanner = "https://storage.googleapis.com/bucket-motioncredit-assets/web-banner.png"

  const navigateToPromoPage = () => {
    navigate.push('/promo')
  }

  return (
    <Carousel className="home-carousel-container" touch={true} >
      <Carousel.Item interval={1000}>
          <img className="banner" src={WebBanner} alt="First slide" onClick={() => navigateToPromoPage()}/>
          <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img className="banner" src={Banner1} alt="Second slide" />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img className="banner" src={Banner2} alt="Third slide" />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img className="banner" src={Banner3} alt="Fourth slide" />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
export default CarouselComponent;
