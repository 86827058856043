import React from "react";

const InformasiDanaHaji = () => {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-md-6 py-4">
          <div className="landing-head-orange">Informasi</div>
          <div className="landing-head-desc mb-5">
            Informasi Pembiayaan Produk MNC Dana Haji
          </div>

          <div className="row tutorial-menu active mt-3">
            <div className="col-2 tutorial-number">01</div>
            <div className="col-10 tutorial-right">Skema Pembiayaan</div>
          </div>

          <div className="row tutorial-menu mt-3">
            <div className="col-2 tutorial-number">02</div>
            <div className="col-10 tutorial-right">Syarat Pengajuan</div>
          </div>

          <div className="row tutorial-menu mt-3">
            <div className="col-2 tutorial-number">03</div>
            <div className="col-10 tutorial-right">Dokumen Persyaratan</div>
          </div>

          <div className="row tutorial-menu mt-3">
            <div className="col-2 tutorial-number">04</div>
            <div className="col-10 tutorial-right">FAQ</div>
          </div>
        </div>

        <div className="col-md-6 py-4 d-flex justify-content-center align-items-center">
          <div className="informasi-dana-card">
            <div className="siap-bold-txt-black my-3">Skema Pembiayaan</div>
            <div className="row d-flex justify-content-between my-3">
              <div className="col-5">Akad pembiayaan</div>
              <div className="col-1">:</div>
              <div className="col-md-6 siap-bold-txt-black">
                Al Qardh dan Kafalah Bil Ujroh
              </div>
            </div>

            <div className="row d-flex justify-content-between my-3">
              <div className="col-5">Plafond pembiayaan</div>
              <div className="col-1">:</div>
              <div className="col-md-6 siap-bold-txt-black">Rp. 25.000.000</div>
            </div>

            <div className="row d-flex justify-content-between my-3">
              <div className="col-5">Tenor</div>
              <div className="col-1">:</div>
              <div className="col-md-6 siap-bold-txt-black">12-60 Bulan</div>
            </div>

            <div className="siap-bold-txt-black my-3">Biaya - Biaya</div>

            <div className="row d-flex justify-content-between my-3">
              <div className="col-5">Biaya Administrasi</div>
              <div className="col-1">:</div>
              <div className="col-md-6 siap-bold-txt-black">
                Rp. 1.000.000,00
              </div>
            </div>

            <div className="row d-flex justify-content-between my-3">
              <div className="col-5">Asuransi</div>
              <div className="col-1">:</div>
              <div className="col-md-6 siap-bold-txt-black">
                Syariah (Personal Acciden)
              </div>
            </div>

            <div className="row d-flex justify-content-between my-3">
              <div className="col-5">Proses Porsi Haji</div>
              <div className="col-1">:</div>
              <div className="col-md-6 siap-bold-txt-black">3-4 Hari kerja</div>
            </div>

            <div className="row d-flex justify-content-between my-3">
              <div className="col-5">Jaminan Aset</div>
              <div className="col-1">:</div>
              <div className="col-md-6 siap-bold-txt-black">Tanpa jaminan</div>
            </div>

            <div className="row d-flex justify-content-between my-3">
              <div className="col-5">DP (Down Payment)</div>
              <div className="col-1">:</div>
              <div className="col-md-6 siap-bold-txt-black">Tanpa DP</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformasiDanaHaji;
