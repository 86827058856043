import React from "react";
import { Carousel } from "react-bootstrap";

const TestimoniLandingPage = () => {
  return (
    <div className="container my-5">
      <div className="row mb-5">
        <div className="col-12">
          <h5 className="landing-head-orange text-center">Testimoni</h5>
          <h2 className="siap-bold-txt text-center">
            Pendapat Mereka Tentang Motion Credit
          </h2>
        </div>
      </div>

      <div className="row mt-3 justify-content-center">
        <div className="col-12">
          <Carousel className="testimoni-carousel">
            <Carousel.Item>
              <div className="embed-responsive embed-responsive-16by9 d-block w-100">
                <iframe
                  src="https://www.youtube.com/embed/qec8FjZCv9A"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="row mt-3 justify-content-center">
                <div className="col-12">
                  <p className="testimoni-desc text-center">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Blanditiis quaerat itaque repellendus, atque deleniti
                    nostrum enim iure quo rerum! Dolorem?
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="embed-responsive embed-responsive-16by9 d-block w-100">
                <iframe
                  src="https://www.youtube.com/embed/V6HD3C0jtKU"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="row mt-3 justify-content-center">
                <div className="col-12">
                  <p className="testimoni-desc text-center">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Blanditiis quaerat itaque repellendus, atque deleniti
                    nostrum enim iure quo rerum! Dolorem?
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="embed-responsive embed-responsive-16by9 d-block w-100">
                <iframe
                  src="https://www.youtube.com/embed/5Ox3-2EoBB8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="row mt-3 justify-content-center">
                <div className="col-12">
                  <p className="testimoni-desc text-center">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Blanditiis quaerat itaque repellendus, atque deleniti
                    nostrum enim iure quo rerum! Dolorem?
                  </p>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default TestimoniLandingPage;
