import React from "react";
import TtgSiap from "assets/images/shutterstock.png";
const TentangSiap = () => {
  return (
    <>
      <div className="section-space50">
        <div className="row">
          <div className="col-md-6">
            <h3 className="siap-bold-txt">Tentang Motion Credit</h3>
            <p className="landing-desc">
              Motion Credit adalah brand untuk pembiayaan digital dari PT. MNC
              Guna Usaha Indonesia (MNC GUI) yang bergerak sebagai perusahaan
              multfinance. MNC GUI terdaftar dan berizin di OJK berdasarkan
              surat KEP/482/NB.1/2015 pada 10 Maret 2015.
            </p>
          </div>
          <div className="col-md-6">
            <img
              src={TtgSiap}
              alt="image-investasi"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>

      <div className="section-space50">
        <div className="row">
          <div className="col-md-6">
            <h3 className="siap-bold-txt">Visi</h3>
            <p className="landing-desc">
              Menjadi aplikasi satu atap untuk semua kebutuhan pembiayaan yang
              disediakan oleh MNC Financial Services
            </p>
          </div>
          <div className="col-md-6">
            <h3 className="siap-bold-txt">Misi</h3>
            <p className="landing-desc">
              Digitalisasi proses pengajuan dan proses pembayaran pembiayaan.
              Transparansi dan kemudahan dalam pengajuan.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default TentangSiap;
