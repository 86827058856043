import React, { useContext, useState, useEffect } from "react";
import { ProjectContext } from "context/ProjectContext";
import { useHistory, useLocation } from "react-router-dom";
import SideBar from "components/SideBar";
import NavBar2 from "components/NavbarBorrow";

import ModalVerifSuccess from "components/ModalVerifSuccess";
import { APIPUBLIC } from "config/api";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const VerifyEmail = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const [modalSuccess, setModalSuccess] = useState(false);
  const router = useHistory();
  let query = useQuery();

  const token = query.get("token");
  const application_type = query.get("application_type");
  const application_id = query.get("application_id");

  const verifyEmail = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const body = {
        token,
      };

      const res = await APIPUBLIC.post("/users/email/verify", body);

      if (res.data.success) {
        setModalSuccess(true);
      } else {
        alert("Verifikasi Email Gagal");
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  const handleRedirect = () => {
    if (application_type === "danamobil_application") {
      return router.push(`/daftar-dana-mobil/${application_id}`);
    } else if (application_type === "danarumah_application") {
      return router.push(`/daftar-dana-rumah/${application_id}`);
    } else if (application_type === "danahaji_application") {
      return router.push(`/daftar-dana-haji/${application_id}`);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <>
      <ModalVerifSuccess
        show={modalSuccess}
        handleClose={() => setModalSuccess(false)}
        route={() => handleRedirect()}
      />
      <div className={state.dim ? "dim" : ""} />

      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 content-container pb-5">
            <div className="container">
              <NavBar2 />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default VerifyEmail;
