import React from "react";
import Logo from "assets/images/logo-color.png";
import LogoColor from "assets/images/logo_motion.png";
import MNC from "assets/images/mnc-group.png";
import Instagram from "assets/images/instagram.png";
import FaceBook from "assets/images/facebook.png";
import Youtube from "assets/images/youtube.png";
import Phone from "assets/images/phone.png";
import Amplop from "assets/images/amplop.png";

const FooterContent = () => {
  return (
    <footer>
      <div className="bg-default section-space80">
        <div className="container">
          <div className="row">
            <div className="col-md-2 mb-3">
              <h5 className="mb-3">Layanan</h5>
              <ul className="listnone">
                <li>Pendanaan</li>
                <li>Peminjaman</li>
                <li>Pembiayaan Haji</li>
              </ul>
            </div>
            <div className="col-md-2 mb-3">
              <h5 className="mb-3">Dukungan</h5>
              <ul className="listnone">
                <li>Blog</li>
                <li>FAQ</li>
                <li>Hubungi Kami</li>
              </ul>
            </div>
            <div className="col-md-2 mb-3">
              <h5 className="mb-3">Informasi</h5>
              <ul className="listnone">
                <li>Motion Credit</li>
              </ul>
            </div>
            <div className="col-md-2 mb-3">
              <h5 className="mb-3">Legal</h5>
              <ul className="listnone">
                <li>Disclaimer</li>
                <li>
                  <a
                    href="/syarat-dan-ketentuan"
                    target="_self"
                    className="footer-link"
                  >
                    Syarat dan Ketentuan
                  </a>
                </li>
                <li>
                  <a
                    href="/kebijakan-privasi"
                    target="_self"
                    className="footer-link"
                  >
                    Kebijakan Privasi
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 mb-3">
              <h5 className="text-right mb-3">Motion Credit</h5>
              <ul className="listnone2">
                <li>MNC Financial Center Building 21th Floor</li>
                <li>Jl Kebon Sirih No. 21 - 27</li>
                <li>Jakarta Pusat 10340</li>
              </ul>
            </div>
          </div>

          <div className="row mt-3">
            {/* <div className="col-md-6">
              <h5 className="mt-4">Channel Resmi Motion Credit</h5>

              <div className="row mt-3 footer-socmed">
                <div className="footer-socmed-card">
                  <img src={Instagram} className="contact-icon" alt="ig" />
                  <p className="contact-font">Motiondanain</p>
                </div>
                <div className="footer-socmed-card">
                  <img src={FaceBook} className="contact-icon" alt="fb" />
                  <p className="contact-font">Motiondanain</p>
                </div>
                <div className="footer-socmed-card">
                  <img src={Youtube} className="contact-icon" alt="yt" />
                  <p className="contact-font">Motiondanain</p>
                </div>
              </div>
            </div> */}

            <div className="col-md-6">
              <h5 className="mt-4">Customer Care</h5>

              <div className="row mt-3 footer-socmed">
                <div className="footer-socmed-card">
                  <img src={Phone} className="contact-icon" alt="ig" />
                  <p className="contact-font">1500 899 ex 7</p>
                </div>

                <div className="footer-socmed-card">
                  <img src={Amplop} className="contact-icon" alt="yt" />
                  <p className="contact-font">cs@motioncredit.id</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tiny-footer">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 footer-bottom">
              <img src={LogoColor} className="foo-logo mr-2" alt="logo" />
              <span className="pt-1">© 2021 Motion Credit</span>
            </div>

            <div class="col-md-6 footer-bottom-right">
              <span className="mr-2 pt-3">Member dari</span>
              <img src={MNC} className="mnc-group" alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterContent;
