import React from "react";
import NavBar from "components/NavBar";
import Disclaimer from "components/Disclaimer";
import CarouselComponent from "components/CarouselComponent";
import Dapatkan from "components/DapatkanPendanaan";
import FooterContent from "components/FooterContent";
import Search from "components/SearchForm";
import { Form } from "react-bootstrap";
import Produk from "components/ProdukKami";
import Pertanyaan from "components/PertanyaanUmum";
import Location from "assets/images/icon-location@3x.png";
import Location2 from "assets/images/lokasi-2@3x.png";
const ProdukHajiPage = () => {
  return (
    <>
      <NavBar />

      <div className="container">
        <div className="section-space150">
          <Search />
          <Produk />
          <Pertanyaan />
          <div className="row d-flex justify-content-center mt-5">
            <h5 className="siap-bold-txt">Anda memiiki pertanyaan lagi ?</h5>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <span className="pertanyaan-small-txt">
              Untuk detailnya bisa hubungi di :
            </span>
          </div>

          <div className="row d-flex align-items-center justify-content-center mt-5">
            <div className="col-md-6">
              <div className="informasi-contact-container">
                <div className="informasi-contact-card">
                  <img src={Location} alt="logo-kontak" />
                  <div className="siap-bold-txt">(021 - 3244758)</div>
                </div>

                <div className="informasi-contact-card">
                  <img src={Location2} alt="logo-kontak" />
                  <div className="siap-bold-txt">Klik untuk ke lokasi</div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="row align-items-center justify-content-center">
                <img
                  src={Location2}
                  alt="logo-lokasi"
                  style={{
                    cursor: "pointer",
                    width: "102px",
                    height: "102px",
                    alignSelf: "center",
                  }}
                />
              </div>
              <div className="row align-items-center justify-content-center">
                <p className="siap-bold-txt">Klik untuk ke lokasi</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <Dapatkan />

      <Disclaimer />
      <FooterContent />
    </>
  );
};

export default ProdukHajiPage;
