import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";

import { APIPUBLIC } from "config/api";

const PertanyaanUmum = () => {
  const [faqs, setFaqs] = useState(null);

  const getFAQS = async () => {
    try {
      const res = await APIPUBLIC.get("/references/faqs?label=platform");
      if (res.data.success) {
        setFaqs(res.data.data);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  useEffect(() => {
    getFAQS();
  }, []);

  return (
    <>
      {/* <h5 className="siap-bold-txt text-center section-space85">
        Pertanyaan Umum
      </h5> */}

      <div className="d-flex justify-content-center pertanyaan-accordion-container">
        {faqs && (
          <Accordion style={{ width: "100%" }}>
            {faqs.map((faq) => (
              <Card className="card-accor mb-3" key={faq.id}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={faq.id}
                  className="cursor-pointer"
                >
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-10 question-head-txt siap-bold-txt">
                      {faq.question}
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                      <FaChevronDown color="#0c135f" />
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={faq.id}>
                  <Card.Body>{faq.answer}</Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        )}
      </div>
    </>
  );
};
export default PertanyaanUmum;
