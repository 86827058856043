import React from "react";
import { Modal, Button } from "react-bootstrap";
import SuccessRocket from "assets/icons/success-rocket@2x.png";

const ModalVerifSuccess = ({ show, handleClose, route, isSpouse, isPhone }) => {
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body className="modal-verification">
        <img src={SuccessRocket} alt="alert logo" className="modal-nopol-img" />

        <h1 className="modal-nopol-header mt-4">
          Verifikasi {!isPhone ? "Email" : "Nomor Handphone"} Berhasil
        </h1>
        <p className="modal-nopol-desc">
          {isSpouse
            ? `Terima kasih ${
                !isPhone ? "email" : "nomor handphone"
              } anda berhasil di verifikasi, silahkan lanjutkan form pendaftaran pengajuan pasangan anda`
            : `Anda dapat melanjutkan proses berikutnya dengan \n menekan tombol dibawah ini`}
        </p>

        <Button className="btn-next" onClick={route}>
          {!isSpouse ? "Lanjut" : "Tutup"}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalVerifSuccess;
