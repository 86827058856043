import React from "react";
import { Button } from "react-bootstrap";
import Mobil from "assets/icons/icon-product-mobil-big.png";
import Mobil2 from "assets/icons/icon-product-mobil-big@2x.png";
import Mobil3 from "assets/icons/icon-product-mobil-big@3x.png";

const DanaMobil = () => {
  return (
    <div className="container">
      <div className="section-space50">
        <div className="row">
          <div className="col mt-5">
            <div className="row mt-3">
              <div className="col">
                <h3 className="landing-head-desc">MNC Dana Mobil</h3>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="text-justify">
                  Solusi pembiayaan cepat kredit multiguna dengan Agunan BPKB
                  kendaraan roda empat. MNC Dana Mobil memiliki keunggulan
                  antara lain persyaratan mudah, proses cepat, bunga bersaing,
                  BPKB aman dan jaringan kantor cabang hampir di seluruh wilayah
                  Indonesia
                </p>
              </div>
            </div>
            {/* <div className="row mt-3">
              <div className="col">
                <button className="btn btn-next w-auto">Selengkapnya</button>
              </div>
            </div> */}
          </div>

          <div className="col d-flex justify-content-center">
            <img
              src={Mobil}
              alt="logo-spin"
              srcSet={`${Mobil2} 866w, ${Mobil3} 1314w`}
              style={{ width: "450px", height: "450px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DanaMobil;
