import React from "react";
import { Button } from "react-bootstrap";
import SiapWork from "assets/images/siap-works.png";
const ProdukLandingPage = () => {
  return (
    <div className="container section-home">
      <div className="row d-flex align-items-center">
        <div className="col-md-6">
          <div className="landing-head-orange">Produk</div>
          <div className="landing-head-desc">
            Cari tahu bagaimana Motion Credit bekerja untuk anda
          </div>
          <p className="landing-desc">
            Pengguna mengunduh dan mendaftar ke aplikasi Motion Credit. Setelah
            terdaftar pengguna dapat langsung melakukan pengajuan pembiayaan
            sesuai dengan kebutuhan dan jenis agunan. Setelah pengajuan selesai,
            pengguna dapat melihat status pengajuan langsung di aplikasi Motion
            Credit. Mudah, Transparan, dan Aman.
          </p>

          {/* <button className="landing-btn">Selengkapnya</button> */}
        </div>
        <div className="col-md-6">
          <img src={SiapWork} className="siap-work" alt="siap" />
        </div>
      </div>
    </div>
  );
};
export default ProdukLandingPage;
