import React from "react";
import NavBar from "components/NavBar";
import Play from "../assets/images/goo.png";
import App from "../assets/images/app.png";

const PromoPage = () => {
    const imgUrl = "https://storage.googleapis.com/bucket-motioncredit-assets/web-banner.png"
    const content = "<h4><strong>Promo Cashback Jutaan Rupiah</strong></h4>\n" +
        "<p>Cashback berupa saldo MotionBank, bagi setiap nasabah MotionCredit yang mengajukan pinjaman dengan agunan BPKB mobil atau produk Dana Mobil.</p>\n" +
        "<h5><strong>Periode</strong></h5>\n" +
        "<p>Desember  2023 – Februari 2024</p>\n" +
        "<h5><strong>Syarat &amp; Ketentuan</strong>&nbsp;</h5>\n" +
        "<div style='padding-left: 14px'><ul>\n" +
        "    <li>Program berlaku untuk pengguna MotionCredit yang sudah download dan register serta menjadi nasabah produk Dana Mobil.</li>\n" +
        "    <li>Cashback secara langsung dikirim ke nomor rekening MotionBank yang terdaftar bagi pengguna MotionCredit yang mengajukan pinjaman di Dana Mobil yang sudah mendapatkan pencairan pinjaman.</li>\n" +
        "    <li>Penerima hadiah diwajibkan download dan register aplikasi MotionBank.</li>\n" +
        "    <li>Hadiah saldo&nbsp;MotionBank sebesar Rp500.000,- untuk nasabah yang mendapatkan pinjaman dengan nilai pokok hutang dari Rp100.000.000,-&nbsp;</li>\n" +
        "    <li>Hadiah saldo&nbsp;MotionBank sebesar Rp750.000,- untuk nasabah yang mendapatkan pinjaman dengan nilai pokok hutang Rp100.000.000,- s/d 250.000.000,-&nbsp;</li>\n" +
        "    <li>Hadiah saldo&nbsp;MotionBank sebesar Rp1.000.000,- untuk nasabah yang mendapatkan pinjaman dengan nilai pokok hutang lebih dari Rp250.000.000,-&nbsp;</li>\n" +
        "    <li>Hadiah saldo&nbsp;MotionBank sebesar Rp300.000,- untuk nasabah existing MNC Finance yang melakukan top up (repeat order) produk Dana Mobil&nbsp;</li>\n" +
        "    <li>Hadiah saldo akan dikirimkan secara otomatis max H+14 hari kerja setelah konfirmasi dari team MotionCredit.</li>\n" +
        "    <li>Syarat dan Ketentuan dapat berubah atau berhenti sewaktu-waktu sesuai dengan kebijakan perusahaan yang akan diinfokan terlebih dahulu melalui kanal resmi.</li>\n" +
        "    <li>MotionCredit dapat menggugurkan hak pengguna apabila ditemukan tindakan yang dianggap oleh MotionCredit sebagai penyalahgunaan, kecurangan, dan atau transaksi mencurigakan lainnya.</li>\n" +
        "</ul></div>" +
        "<h5><strong>Untuk promo ini berlaku di kota – kota sebagai berikut : </strong></h5>\n" +
        "<p >Pulau Jawa : JABODETABEK - Serang - Cikupa - Cikarang - Karawang - Bandung - Cirebon - Yogyakarta - Solo - Kediri - Malang - Surabaya - Denpasar</p>\n" +
        "<p >Pulau Sumatra : Binjai - Medan - Pekanbaru - Jambi - Bengkulu - Prabumulih - Palembang - Pangkal Pinang - Pringsewu - Metro - Bandar Lampung </p>\n" +
        "<p >Pulau Kalimantan : Pontianak - Pangkalan Bun - Sampit - Banjarmasin - Balikpapan - Samarinda</p>\n" +
        "<p >Pulau Sulawesi : Manado - Wajo - Makassar - Kendari</p>\n"
    return (
        <div>
            <NavBar/>
            <div className="container pt-5">
                <div className="col">
                    <img
                        src={imgUrl}
                        alt="thumbnail"
                        className="foto"
                        style={{
                            borderRadius: "1.5%",
                            width: "100%",
                            height: "40%",
                            objectFit: "fill",
                        }}
                    />
                    <div style={{height: 16}}/>
                    <div dangerouslySetInnerHTML={{__html: content}}></div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <h5 className="siap-bold-txt text-center">
                            Motion Credit dapat diunduh di google play dan app store secara
                            gratis
                        </h5>
                    </div>
                </div>

                <div className="w-100 mt-5 mb-5 d-flex justify-content-center">
                    <a href={"https://onelink.to/wbbanner"}>
                        <img src={Play} alt="google-play" className="pendanaan-apps mr-2" />
                    </a>
                    <a href={"https://onelink.to/wbbanner"}>
                        <img src={App} alt="app-store" className="pendanaan-apps" />
                    </a>
                </div>
            </div>
        </div>
    );
};
export default PromoPage;
