import React, { useContext, useState } from "react";
import { ProjectContext } from "context/ProjectContext";
import { Modal, Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API } from "config/api";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

function SlideTransition(props) {
  console.log(props);
  return (
    <Slide {...props} direction="up">
      <Alert severity="info" sx={{ width: "100%" }}>
        Ubah Password Berhasil
      </Alert>
    </Slide>
  );
}

const ModalPasswordSettings = ({ show, handleClose }) => {
  const [state, dispatch] = useContext(ProjectContext);
  const [open, setOpen] = useState(false);
  const [vertical, setVertical] = useState("bottom");
  const [horizontal, setHorizontal] = useState("center");
  const [oldPassVisible, setOldPassVisible] = useState(false);
  const [newPassVisible, setNewPassVisible] = useState(false);
  const [repeatPassVisible, setRepeatPassVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      repeat_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string()
        .min(8, "Kata sandi minimal 8 digit")
        .required("*Masukan kata sandi lama"),
      new_password: Yup.string()
        .min(8, "Kata sandi minimal 8 digit")
        .required("*Masukan kata sandi baru"),
      repeat_password: Yup.string()
        .required("*Konfirmasi Kata sandi tidak boleh kosong")
        .when("new_password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("new_password")],
            "Kedua kata sandi harus sama "
          ),
        }),
    }),
  });

  const { old_password, new_password, repeat_password } = formik.values;

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const body = {
        old_password: old_password,
        new_password: new_password,
      };
      const res = await API.post("/me/change-password", body);

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });

      handleClose();
      setOpen(true);

      let timeout = setTimeout(() => {
        setOpen(false);
        clearTimeout(timeout);
      }, 3000);

      formik.setValues({
        old_password: "",
        new_password: "",
        repeat_password: "",
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };

  return (
    <>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={SlideTransition}
        autoHideDuration={3000}
        // message="Ubah Password Berhasil"
        key={vertical + horizontal}
        anchorOrigin={{ vertical, horizontal }}
      />

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body className="p-4">
          <h4 className="siap-bold-txt">Ubah Kata Sandi</h4>
          <div className="notif-setting-subhead">
            Ganti Kata Sandi Akun Anda
          </div>

          <Form className="mt-4" onSubmit={handleChangePassword}>
            <div className="row mb-4">
              <div className="col-md-12">
                <label htmlFor="old-password" className="siap-bold-txt">
                  Masukan Kata Sandi Lama
                </label>
                <Form.Group className="pwd-input">
                  <Form.Control
                    id="old-password"
                    type={!oldPassVisible ? "password" : "text"}
                    className="login-input"
                    placeholder="Kata sandi anda"
                    name="old_password"
                    value={old_password}
                    onChange={(e) =>
                      formik.setValues({
                        ...formik.values,
                        old_password: e.target.value,
                      })
                    }
                    isInvalid={formik.errors.old_password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.old_password}
                  </Form.Control.Feedback>

                  {!oldPassVisible ? (
                    <AiFillEyeInvisible
                      size={22}
                      className="pwd-icon"
                      onClick={() => setOldPassVisible(true)}
                    />
                  ) : (
                    <AiFillEye
                      size={22}
                      className="pwd-icon"
                      onClick={() => setOldPassVisible(false)}
                    />
                  )}
                </Form.Group>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <label htmlFor="new-password" className="siap-bold-txt">
                  Buat Kata Sandi Baru
                </label>
                <Form.Group className="pwd-input">
                  <Form.Control
                    id="new-password"
                    type={!newPassVisible ? "password" : "text"}
                    className="login-input"
                    placeholder="Buat kata sandi baru"
                    name="new_password"
                    value={new_password}
                    onChange={(e) =>
                      formik.setValues({
                        ...formik.values,
                        new_password: e.target.value,
                      })
                    }
                    isInvalid={formik.errors.new_password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.new_password}
                  </Form.Control.Feedback>

                  {!newPassVisible ? (
                    <AiFillEyeInvisible
                      size={22}
                      className="pwd-icon"
                      onClick={() => setNewPassVisible(true)}
                    />
                  ) : (
                    <AiFillEye
                      size={22}
                      className="pwd-icon"
                      onClick={() => setNewPassVisible(false)}
                    />
                  )}
                </Form.Group>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <label htmlFor="repeat-password" className="siap-bold-txt">
                  Ulangi Kata Sandi
                </label>
                <Form.Group className="pwd-input">
                  <Form.Control
                    id="repeat-password"
                    type={!repeatPassVisible ? "password" : "text"}
                    className="login-input"
                    placeholder="Masukan kata sandi untuk verifikasi"
                    name="repeat_password"
                    value={repeat_password}
                    onChange={(e) =>
                      formik.setValues({
                        ...formik.values,
                        repeat_password: e.target.value,
                      })
                    }
                    isInvalid={formik.errors.repeat_password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.repeat_password}
                  </Form.Control.Feedback>

                  {!repeatPassVisible ? (
                    <AiFillEyeInvisible
                      size={22}
                      className="pwd-icon"
                      onClick={() => setRepeatPassVisible(true)}
                    />
                  ) : (
                    <AiFillEye
                      size={22}
                      className="pwd-icon"
                      onClick={() => setRepeatPassVisible(false)}
                    />
                  )}
                </Form.Group>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-12">
                <Button
                  variant="primary"
                  block
                  type="submit"
                  className="login-btn"
                  disabled={
                    !formik.isValid ||
                    !old_password ||
                    !new_password ||
                    !repeat_password
                  }
                >
                  Simpan dan Ubah
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalPasswordSettings;
