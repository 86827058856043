import React from "react";
import { Card } from "react-bootstrap";
import Mudah from "assets/images/tumbnail-image-syarat.png";
import Online from "assets/images/tumbnail-image-online.png";
import Aman from "assets/images/tumbnail-image-aman.png";

const CorporateValue = () => {
  return (
    <div className="container-fluid bg-blue">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="landing-head-orange">Keuntungan</div>
            <div className="landing-head-desc">
              Hanya di Motion Credit Anda mendapatkan
            </div>

            <div className="row d-flex justify-content-center mt-4">
              <div className="col-md-4 my-3">
                <Card
                  style={{
                    width: "100%",
                    height: "500px",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    boxShadow: "0 20px 40px 0 #bedcef",
                  }}
                >
                  <Card.Body>
                    <div className="row d-flex justify-content-center mt-3">
                      <img src={Mudah} alt="mudah" />
                    </div>

                    <Card.Title
                      style={{
                        textAlign: "center",
                        marginTop: "34px",
                        marginBottom: "17px",
                      }}
                      className="siap-bold-txt"
                    >
                      Transparan
                    </Card.Title>

                    <Card.Text
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        color: "#3a3a3a",
                      }}
                    >
                      Status pengajuan dapat dilihat secara langsung di aplikasi
                      motion credit, semua biaya dan cara pembayaran juga
                      ditampilkan secara jelas.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-md-4 my-3">
                <Card
                  style={{
                    width: "100%",
                    height: "500px",
                    borderRadius: "20px",
                    borderColor: "transparent",
                    boxShadow: "0 20px 40px 0 #bedcef",
                  }}
                >
                  <Card.Body>
                    <div className="row d-flex justify-content-center mt-3">
                      <img src={Aman} alt="mudah" />
                    </div>

                    <Card.Title
                      style={{
                        textAlign: "center",
                        marginTop: "34px",
                        marginBottom: "17px",
                      }}
                      className="siap-bold-txt"
                    >
                      Aman dan Terpercaya
                    </Card.Title>

                    <Card.Text
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        color: "#3a3a3a",
                      }}
                    >
                      Pembiayaan disediakan oleh perusahaan pembiayaan multiguna
                      yang merupakan bagian dari MNC Financial Services yang
                      memiliki izin dan diawasi oleh OJK.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CorporateValue;
