import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import NavBar from "components/NavBar";
import Footer from "components/FooterContent";

import { APIPUBLIC } from "config/api";

const KebijakandanPrivasiPublic = () => {
  const [privacies, setPrivacies] = useState(null);

  const getPrivacies = async () => {
    try {
      const res = await APIPUBLIC.get(
        "/references/privacy-policies?label=platform"
      );
      setPrivacies(res.data.data[0].content);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getPrivacies();
  }, []);

  return (
    <>
      <NavBar />

      {privacies && (
        <div className="container mt-3 py-5">{parse(privacies)}</div>
      )}

      <Footer />
    </>
  );
};

export default KebijakandanPrivasiPublic;
