import React, { useState, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Icon from "assets/images/search.png";
import Notif from "../assets/icons/iconly-two-tone-notification.svg";
import Flag from "../assets/icons/icon-language.svg";
import Profile from "assets/images/blank_profile.png";
import { ProjectContext } from "context/ProjectContext";
import { Dropdown } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import {
  IoLockClosedOutline,
  IoLockClosed,
  IoSettingsOutline,
  IoSettingsSharp,
  IoNotificationsOutline,
  IoNotifications,
} from "react-icons/io5";

import ModalNotificationSettings from "./ModalNotificationSettings";
import ModalPINSettings from "./ModalPINSettings";
import ModalPasswordSettings from "./ModalPasswordSettings";
import ModalNotification from "./ModalNotification";
import ModalLoading from "./ModalLoading";
import { useQuery, useMutation } from "react-query";
import { API } from "config/api";

const NavBarBorrow = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const [show, setShow] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [notif, setNotif] = useState(false);
  const [lock, setLock] = useState(false);
  const [setting, setSetting] = useState(false);
  const [modalNotifSettings, setModalNotifSettings] = useState(false);
  const [modalPINSettings, setModalPINSettings] = useState(false);
  const [modalPasswordSettings, setModalPasswordSettings] = useState(false);
  const [modalNotif, setModalNotif] = useState(false);
  const router = useHistory();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const getUserInfo = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      const res = await API.get("/me");

      dispatch({
        type: "USER_LOADED",
        payload: res.data.data,
      });

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };

  useEffect(() => {
    if (show || showNotif) {
      dispatch({
        type: "SET_DIM",
        payload: true,
      });
    } else {
      dispatch({
        type: "SET_DIM",
        payload: false,
      });
    }
  }, [show, showNotif]);

  useEffect(() => {
    // getUserInfo();
  }, []);

  return !state.user ? (
    <ModalLoading show handleClose />
  ) : (
    <>
      <ModalNotificationSettings
        show={modalNotifSettings}
        handleClose={() => setModalNotifSettings(false)}
      />
      <ModalPINSettings
        show={modalPINSettings}
        handleClose={() => setModalPINSettings(false)}
      />
      <ModalPasswordSettings
        show={modalPasswordSettings}
        handleClose={() => setModalPasswordSettings(false)}
      />
      <ModalNotification
        show={state.modalNotif}
        handleClose={() =>
          dispatch({
            type: "SET_MODAL_NOTIFICATION",
            payload: false,
          })
        }
      />
      <div className="row mt-5 align-items-center top-navbar">
        <div className="col-md-6">
          <div className="form-group has-search2">
            <img src={Icon} alt="" className="form-control-feedback" />
            <input type="text" className="form-control" placeholder="Search" />
          </div>
        </div>

        <div className="col-md-6 top-navbar-right-container">
          <img
            src={Notif}
            alt="notif-icon"
            className="top-nav-icon swing-hover"
            onClick={() =>
              dispatch({
                type: "SET_MODAL_NOTIFICATION",
                payload: true,
              })
            }
          />
          {/*<img src={Flag} alt="notif-icon" className="top-nav-icon" />*/}

          <Dropdown show={show} ref={wrapperRef}>
            <Dropdown.Toggle
              id="dropdown-basic"
              className="prof-accor"
              onClick={() => setShow(!show)}
            >
              <div className="row align-items-center">
                <div className="col-sm-4">
                  <img src={Profile} alt="mudah" className="profile-nav" />
                </div>
                <div className="col">
                  <div className="row">
                    <span className="navbar-phone">
                      {state.user.phone_number}
                    </span>
                  </div>
                </div>
                <div className="col-sm-2">
                  <FaChevronDown color="#0c135f" />
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="mt-2 py-4 px-4 navbar-menu"
              style={{ borderRadius: "10px", width: "100%", zIndex: 5 }}
            >
              <div
                href="#/action-1"
                className="navbar-drop-menu"
                onMouseEnter={() => setNotif(true)}
                onMouseLeave={() => setNotif(false)}
                onClick={() => {
                  setShow(false);
                  setModalNotifSettings(true);
                }}
              >
                {notif ? (
                  <IoNotifications size={20} className="mr-2" />
                ) : (
                  <IoNotificationsOutline size={20} className="mr-2" />
                )}
                <span>Pengaturan Notifikasi</span>
              </div>
              <div
                href="#/action-2"
                className="navbar-drop-menu"
                onMouseEnter={() => setLock(true)}
                onMouseLeave={() => setLock(false)}
                onClick={() => {
                  setShow(false);
                  setModalPINSettings(true);
                }}
              >
                {lock ? (
                  <IoLockClosed size={20} className="mr-2" />
                ) : (
                  <IoLockClosedOutline size={20} className="mr-2" />
                )}

                <span>Pengaturan PIN</span>
              </div>
              <div
                href="#/action-3"
                className="navbar-drop-menu"
                onMouseEnter={() => setSetting(true)}
                onMouseLeave={() => setSetting(false)}
                onClick={() => {
                  setShow(false);
                  setModalPasswordSettings(true);
                }}
              >
                {setting ? (
                  <IoSettingsSharp size={20} className="mr-2" />
                ) : (
                  <IoSettingsOutline size={20} className="mr-2" />
                )}

                <span>Pengaturan Kata Sandi</span>
              </div>

              <div
                className="navbar-logout siap-bold-txt"
                onClick={() => {
                  dispatch({
                    type: "LOGOUT",
                  });
                  router.push("/login");
                }}
              >
                Logout
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};
export default NavBarBorrow;
