import React, { useContext } from "react";
import { ProjectContext } from "context/ProjectContext";
import SideBar from "components/SideBar";
import Icon from "assets/images/search.png";
import NavBar2 from "components/NavbarBorrow";
import { Breadcrumb } from "react-bootstrap";
import SimulasiForm from "components/FormSimulasi";
import JadwalAngsuranMobilContent from "components/JadwalAngsuranMobilContent";
import ProdukSiap from "components/ProdukSiap";
import Location from "assets/images/icon-location@3x.png";
import Location2 from "assets/images/lokasi-2@3x.png";
import PertanyanUmum from "components/PertanyaanUmum";

const Bantuan = () => {
  const [state, dispatch] = useContext(ProjectContext);

  return (
    <>
      <div className={state.dim ? "dim" : ""} />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0">
            <SideBar />
          </div>

          <div className="col-lg-10 content-container">
            <div className="container pb-4">
              <NavBar2 />

              <div className="bigcard mt-3">
                <div className="row px-3">
                  <div className="col-md-6">
                    <h4 className="siap-bold-txt">Bantuan</h4>
                    <div className="bantuan-subhead">
                      Halo, Bagaimana Kami Bisa Membantu?
                    </div>
                  </div>
                </div>

                <div className="row px-3 mt-4">
                  <div className="col-md-6">
                    <h6 className="siap-bold-txt mb-3">Produk Motion Credit</h6>

                    <ProdukSiap />
                  </div>

                  <div className="col-md-6">
                    <h6 className="siap-bold-txt mb-3">Pertanyaan Umum</h6>
                    <PertanyanUmum />
                  </div>
                </div>

                {/* <div className="row px-3 mt-5">
                  <div className="col-md-6">
                    <h6 className="siap-bold-txt">
                      Anda memiiki pertanyaan lagi ?
                    </h6>
                    <div className="bantuan-subhead">
                      Untuk detailnya bisa hubungi di :
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-4 d-flex flex-column align-items-center">
                        <img
                          src={Location}
                          alt="telp-motion-credit"
                          style={{
                            width: "55px",
                            height: "55px",
                            objectFit: "contain",
                            alignSelf: "center",
                          }}
                        />
                        <div className="bantuan-value-txt mt-2">
                          (021 - 3244758)
                        </div>
                      </div>
                      <div className="col-md-4 d-flex flex-column align-items-center">
                        <img
                          src={Location2}
                          alt="motion-credit-location"
                          style={{
                            width: "55px",
                            height: "55px",
                            alignSelf: "center",
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                        />
                        <div className="bantuan-value-txt mt-2">
                          Klik untuk ke lokasi
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Bantuan;
