import React from "react";
import NavBar from "components/NavBar";
import Disclaimer from "components/Disclaimer";
import ProdukLandingPage from "components/ProdukLandingPage";
import CarouselComponent from "components/CarouselComponent";
import Benefit from "components/Benefit";
import Footer from "components/FooterContent";
import Portofolio from "components/Portofolio";
import Dapatkan from "components/DapatkanPendanaan";
import Tutorial from "components/TutorialLandingPage";
import Testimoni from "components/TestimoniLandingPage";

const LandingPage = () => {
  return (
    <>
      <NavBar />

      <CarouselComponent />

      <ProdukLandingPage />

      <Benefit />

      <Tutorial />

      {/* <Testimoni /> */}

      <Dapatkan />

      <Disclaimer />

      <Footer />
    </>
  );
};

export default LandingPage;
