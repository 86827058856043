import React, { useEffect, useContext } from "react";
import { ThemeProvider } from "@mui/material/styles";
import Landing from "pages/LandingPage";
import ListProduk from "pages/ListProdukPage";
import ProdukHaji from "pages/ProdukHajiPage";
import Informasi from "pages/InformasiPage";
import TentangKami from "pages/TentangKamiPage";
import DetailBerita from "pages/DetailBeritaPage";
import DetailPromo from "pages/DetailPromoPage";
import PromoPage from "pages/PromoPage";
import Bantuan from "pages/Bantuan";
import ScrollToTop from "config/ScrollToTop";
import VerifyEmail from "pages/VerifyEmail";
import VerifyEmailSpouse from "pages/VerifyEmailSpouse";
import VerifyPhoneSpouse from "pages/VerifyPhoneSpouse";
import SyaratdanKetentuanPublic from "pages/SyaratdanKetentuanPublic";
import KebijakandanPrivasiPublic from "pages/KebijakandanPrivasiPublic";
import HapusAkunPublic from "pages/HapusAkunPublic";
import { ProjectContext } from "context/ProjectContext";
import "assets/scss/style.scss";
import "./App.css";
import theme from "config/theme";
import Callback from "pages/Callback";
import CallbackActivation from "pages/CallbackActivation";
import CallbackEsign from "pages/CallbackEsign";


import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }

const token = localStorage.getItem("token");
// console.log("token", token);

function App() {
  const [state, dispatch] = useContext(ProjectContext);

  useEffect(() => {
    const loadUser = async () => {
      try {
        let parsedToken = token ? JSON.parse(token) : null;
        // console.log("parsedToken", parsedToken);

        if (token && parsedToken.access_token) {
          dispatch({
            type: "LOGIN",
            payload: token,
          });
        } else {
          dispatch({
            type: "AUTH_ERROR",
          });
          dispatch({
            type: "LOGOUT",
          });
        }
      } catch (err) {
        console.log(err);
        dispatch({
          type: "AUTH_ERROR",
        });
      }
    };

    loadUser();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Landing} />          
          <Route exact path="/verify-email" component={VerifyEmail} />
          <Route
            exact
            path="/verify-email-spouse"
            component={VerifyEmailSpouse}
          />
          <Route
            exact
            path="/verify-phone-spouse"
            component={VerifyPhoneSpouse}
          />
          <Route
            exact
            path="/syarat-dan-ketentuan"
            component={SyaratdanKetentuanPublic}
          />
          <Route
            exact
            path="/kebijakan-privasi"
            component={KebijakandanPrivasiPublic}
          />
                    <Route
            exact
            path="/kebijakan-hapus-akun"
            component={HapusAkunPublic}
          />        
          <Route exact path="/list-produk" component={ListProduk} />
          <Route exact path="/produk-haji" component={ProdukHaji} />
          <Route exact path="/informasi" component={Informasi} />
          <Route exact path="/tentang-kami" component={TentangKami} />
          <Route exact path="/detailberita" component={DetailBerita} />
          <Route exact path="/promo" component={PromoPage} />
          <Route exact path="/detailpromo" component={DetailPromo} />
          <Route exact path="/bantuan" component={Bantuan} />
          <Route exact path="/callback" component={Callback} />
          <Route exact path="/callback/activation" component={CallbackActivation} />
          <Route exact path="/callback/sign" component={CallbackEsign} />          
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
