import React from "react";
import Icon from "assets/images/search.png";
import { Form, Button } from "react-bootstrap";
const SearchForm = () => {
  return (
    <>
      <div className="row mb-4">
        <div className="col-md-12 d-flex justify-content-center ">
          <h2 className="siap-bold-txt">
            Hi, Butuh bantuan singkat cari disini
          </h2>
        </div>
      </div>

      <div className="has-search-container">
        <div className="form-group has-search">
          <img src={Icon} alt="" className="form-control-feedback mt-3 ml-2" />
          <input type="text" className="form-control" placeholder="Search" />
          <Button variant="primary" className="button-search" active>
            Cari
          </Button>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 d-flex justify-content-center">
          <span className="search-mid-desc">
            atau pilih kategori untuk menemukan dengan cepat apa yang kamu
            butuhkan
          </span>
        </div>
      </div>
    </>
  );
};
export default SearchForm;
