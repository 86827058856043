import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import NavBar from "components/NavBar";
import Footer from "components/FooterContent";

import { APIPUBLIC } from "config/api";

const SyaratdanKetentuanPublic = () => {
  const [tncs, setTncs] = useState(null);

  const getTncs = async () => {
    try {
      const res = await APIPUBLIC.get("/references/tncs?label=platform");
      setTncs(res.data.data[0].content);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getTncs();
  }, []);

  return (
    <>
      <NavBar />

      {tncs && <div className="container mt-3 py-5">{parse(tncs)}</div>}

      <Footer />
    </>
  );
};

export default SyaratdanKetentuanPublic;
