import React from "react";
import { Modal, Button } from "react-bootstrap";
import FailedAlert from "../assets/icons/red-alert@2x.png";
import SuccessRocket from "../assets/icons/success-rocket@2x.png";

function Component({ show, handleClose, route, isSuccess }) {
    return (
        <Modal
            centered
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Body className="modal-verification">
                <img src={isSuccess ? SuccessRocket : FailedAlert} alt="alert logo" className="modal-nopol-img" />

                <h1 className="modal-nopol-header mt-4">
                {!isSuccess ? `Aktivasi Akun Gagal` : `Aktivasi Akun Berhasil`}
                </h1>
                <p className="modal-nopol-desc">
                {!isSuccess ? 
                `Mohon maaf aktivasi akun anda belum berhasil,
                silahkan melakukan aktivasi kembali dengan
                menekan tombol dibawah ini` 
                : 
                `Terima kasih, akun anda berhasil di aktivasi, 
                silahkan melanjutkan proses pengajuan anda dengan 
                menekan tombol dibawah ini`}
                </p>

                <Button className="btn-next" onClick={route}>
                Tutup
                </Button>
            </Modal.Body>
        </Modal>
    );
};
export default Component;