import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Haji from "../assets/icons/icon-product-dana-haji.png";
import Investasi from "../assets/icons/icon-product-investasi.png";
import Info from "../assets/icons/info-rdl-2.png";

const ModalNotification = ({ show, handleClose }) => {
  const [checked, setChecked] = useState(false);

  return (
    <Modal centered show={show} onHide={handleClose} size="sm">
      <Modal.Body className="p-2">
        <div className="notif-menu-container mb-2">
          <div className="mr-2 notif-icon-container">
            <img src={Haji} alt="siap-haji" className="notif-icon" />
          </div>
          <div className="notif-desc-container">
            <div className="notif-menu-title siap-bold-txt mb-1">
              Pengajuan Motion Dana Haji
            </div>
            <div className="notif-menu-date mb-1">Kamis, 11 Feb 2021</div>
            <div className="notif-menu-desc">
              Pendaftaran haji anda telah diverifikasi, lanjut ketahap
              berikutnya, untuk dapat meneruskan proses pengajuan
            </div>
          </div>
        </div>

        <div className="notif-menu-container mb-2">
          <div className="mr-2 notif-icon-container">
            <img src={Investasi} alt="siap-haji" className="notif-icon" />
          </div>
          <div className="notif-desc-container">
            <div className="notif-menu-title siap-bold-txt mb-1">
              Berinvestasi di Motion Credit yuk
            </div>
            <div className="notif-menu-date mb-1">Kamis, 11 Feb 2021</div>
            <div className="notif-menu-desc">
              Yuk daftar dan rasakan keuntungannya berada di siap invest
            </div>
          </div>
        </div>

        <div className="notif-menu-container mb-2">
          <div className="mr-2 notif-icon-container">
            <img src={Info} alt="siap-haji" className="notif-icon" />
          </div>
          <div className="notif-desc-container">
            <div className="notif-menu-title siap-bold-txt mb-1">
              Pembaruan sistem 1.1.2
            </div>
            <div className="notif-menu-date mb-1">Kamis, 11 Feb 2021</div>
            <div className="notif-menu-desc">
              Terdapat update sistem pada Motion Credit
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalNotification;
