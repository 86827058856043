import React from "react";
import Ojk from "assets/images/bitmap.png";
import Afpi from "assets/images/afpi.png";
import Mnc from "assets/images/mnc.png";
import SiapWork from "assets/images/siap-works.png";

const TentangContent = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <p className="landing-head-orange">Tentang kami</p>
        <h2 className="siap-bold-txt mt-3">
          Aplikasi Finansial Terbaik Untuk Masyarakat Indonesia
        </h2>
        <p className="landing-desc mt-4" style={{ textAlign: "left" }}>
          Motion Credit membantu kamu mendapatkan Pendanaan dengan mudah, aman
          dan tanpa ribet.
        </p>

        <div className="row">
          <div className="col-md-4 mt-3">
            <div className="siap-bold-txt my-2">Berizin dan Diawasi</div>
            <img
              src={Ojk}
              alt="logo-ojk"
              style={{
                width: "100%",
                maxWidth: "100px",
                height: "auto",
              }}
            />
          </div>

          <div className="col-md-4 mt-3">
            <div className="siap-bold-txt my-2">Bagian Dari</div>
            <img
              src={Mnc}
              alt="logo-mnc"
              style={{
                width: "100%",
                maxWidth: "100px",
                height: "auto",
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <img src={SiapWork} alt="logo-work" className="w-100" />
      </div>
    </div>
  );
};
export default TentangContent;
