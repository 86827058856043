import React, { useContext, useState, useEffect } from "react";
import { ProjectContext } from "context/ProjectContext";
import { useLocation } from "react-router-dom";

import ModalVerifSuccess from "components/ModalVerifSuccess";
import { APIPUBLIC } from "config/api";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const VerifyEmailSpouse = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const [modalSuccess, setModalSuccess] = useState(false);
  let query = useQuery();

  const token = query.get("token");
  const application_type = query.get("application_type");
  const application_id = query.get("application_id");

  const verifyEmail = async () => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });

      let application;

      if (application_type === "danamobil_application") {
        application = "danamobil";
      } else if (application_type === "danarumah_application") {
        application = "danarumah";
      } else if (application_type === "danahaji_application") {
        application = "danahaji";
      }

      const body = {
        token,
      };

      const res = await APIPUBLIC.post(
        `/${application}/verify-spouse-email`,
        body
      );

      if (res.data.success) {
        setModalSuccess(true);
      } else {
        alert("Verifikasi Email Gagal");
      }

      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
      alert(error);
    }
  };

  const handleRedirect = () => {
    // router.push("/login");
    window.close("", "_parent", "");
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <>
      <ModalVerifSuccess
        show={modalSuccess}
        handleClose={() => setModalSuccess(false)}
        route={() => handleRedirect()}
        isSpouse={true}
      />
      <div className={state.dim ? "dim" : ""} />
    </>
  );
};
export default VerifyEmailSpouse;
