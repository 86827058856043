import React from "react";
import Rumah from "assets/icons/icon-product-rumah-big.png";
import Rumah2 from "assets/icons/icon-product-rumah-big@2x.png";
import Rumah3 from "assets/icons/icon-product-rumah-big@3x.png";

const DanaRumah = () => {
  return (
    <benefit>
      <div className="section-space50">
        <div className="container">
          <div className="row">
            <div className="col-md-7 d-flex justify-content-center">
              <img
                src={Rumah}
                alt="logo-spin"
                srcSet={`${Rumah2} 866w, ${Rumah3} 1314w`}
                style={{ width: "450px", height: "450px" }}
              />
            </div>

            <div className="col-md-5 d-flex flex-column justify-content-center">
              <div className="row mt-3">
                <div className="col">
                  <h3 className="landing-head-desc">MNC Dana Rumah</h3>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className="text-justify">
                    Dana dengan Rumahmu. Merupakan solusi pembiayaan cepat
                    kredit multiguna dengan Agunan Sertifikat Kepemilikan Rumah.
                    MNC Dana Rumah memiliki keunggulan antara lain persyaratan
                    mudah, proses cepat, bunga bersaing, sertifikat aman dan
                    jaringan kantor cabang hampir di seluruh wilayah Indonesia.
                  </p>
                </div>
              </div>
              {/* <div className="row">
                <div className="col">
                  <button className="btn btn-next w-auto">Selengkapnya</button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </benefit>
  );
};
export default DanaRumah;
