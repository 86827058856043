import React from "react";
import { Modal, Accordion, Card } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";

const ModalQuestionAnswer = ({ show, handleClose, faqs }) => {
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="modal-payment-header p-3">
          <h5 className="modal-payment-head-txt">FAQ Motion Credit</h5>
        </div>
        <Accordion style={{ width: "100%" }}>
          {faqs &&
            faqs.map((faq) => (
              <Card className="card-accor mb-3" key={faq.id}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={faq.id}
                  className="cursor-pointer"
                >
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-10 question-head-txt siap-bold-txt">
                      {faq.question}
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                      <FaChevronDown color="#0c135f" />
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={faq.id}>
                  <Card.Body>{faq.answer}</Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};

export default ModalQuestionAnswer;
