import React from "react";
import { Card } from "react-bootstrap";
import Mudah from "assets/images/tumbnail-image-syarat.png";
import Online from "assets/images/tumbnail-image-online.png";
import Aman from "assets/images/tumbnail-image-aman.png";

const Benefit = () => {
  return (
    <>
      <div className="container-fluid bg-blue">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <div className="landing-head-orange">Keuntungan</div>
              <div className="landing-head-desc">
                Hanya di Motion Credit Anda mendapatkan
              </div>

              <div className="row mt-4">
                <div className="col-md-4 my-3">
                  <Card
                    style={{
                      width: "100%",
                      height: "500px",
                      borderRadius: "20px",
                      borderColor: "transparent",
                      boxShadow: "0 20px 40px 0 #bedcef",
                    }}
                  >
                    <Card.Body>
                      <div className="row d-flex justify-content-center mt-3">
                        <img src={Mudah} alt="mudah" />
                      </div>

                      <Card.Title
                        style={{
                          textAlign: "center",
                          marginTop: "34px",
                          marginBottom: "17px",
                        }}
                        className="siap-bold-txt"
                      >
                        Mudah
                      </Card.Title>

                      <Card.Text
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          color: "#3a3a3a",
                        }}
                      >
                        Unduh Motion Credit, Unggah Dokumennya dan tunggu
                        hasilnya
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-4 my-3">
                  <Card
                    style={{
                      width: "100%",
                      height: "500px",
                      borderRadius: "20px",
                      borderColor: "transparent",
                      boxShadow: "0 20px 40px 0 #bedcef",
                    }}
                  >
                    <Card.Body>
                      <div className="row d-flex justify-content-center mt-3">
                        <img src={Online} alt="mudah" />
                      </div>

                      <Card.Title
                        style={{
                          textAlign: "center",
                          marginTop: "34px",
                          marginBottom: "17px",
                        }}
                        className="siap-bold-txt"
                      >
                        100 % Secara Online
                      </Card.Title>

                      <Card.Text
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          color: "#3a3a3a",
                        }}
                      >
                        Ajukan dimana saja dan kapan saja melalui aplikasi
                        Motion Credit
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-4 my-3">
                  <Card
                    style={{
                      width: "100%",
                      height: "500px",
                      borderRadius: "20px",
                      borderColor: "transparent",
                      boxShadow: "0 20px 40px 0 #bedcef",
                    }}
                  >
                    <Card.Body>
                      <div className="row d-flex justify-content-center mt-3">
                        <img src={Aman} alt="mudah" />
                      </div>

                      <Card.Title
                        style={{
                          textAlign: "center",
                          marginTop: "34px",
                          marginBottom: "17px",
                        }}
                        className="siap-bold-txt"
                      >
                        Aman dan nyaman
                      </Card.Title>

                      <Card.Text
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          color: "#3a3a3a",
                        }}
                      >
                        Dokumen dijamin keamanannya dengan proses yang lebih
                        cepat
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Benefit;
