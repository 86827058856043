import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const ModalPINSettings = ({ show, handleClose }) => {
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body className="p-4">
        <h4 className="siap-bold-txt">Ubah PIN</h4>
        <div className="notif-setting-subhead">
          PIN harus berupa angka dan 6 digit
        </div>

        <Form className="mt-4">
          <Form.Group>
            <div className="row mb-4">
              <div className="col-md-12">
                <label htmlFor="old-pin" className="siap-bold-txt">
                  Masukan PIN Lama
                </label>
                <Form.Control
                  id="old-pin"
                  type="text"
                  className="login-input"
                  placeholder="123456"
                  name="old_pin"
                  // value={phone_number}
                  // onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <label htmlFor="old-pin" className="siap-bold-txt">
                  Buat PIN Baru
                </label>
                <Form.Control
                  id="old-pin"
                  type="text"
                  className="login-input"
                  placeholder="123456"
                  name="new_pin"
                  // value={phone_number}
                  // onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <label htmlFor="old-pin" className="siap-bold-txt">
                  Ulangi PIN
                </label>
                <Form.Control
                  id="old-pin"
                  type="text"
                  className="login-input"
                  placeholder="123456"
                  name="repeat_pin"
                  // value={phone_number}
                  // onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-12">
                <Button
                  variant="primary"
                  block
                  type="submit"
                  className="login-btn"
                >
                  Simpan PIN
                </Button>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPINSettings;
