import React from "react";
import { Button } from "react-bootstrap";
import Shutterstock from "assets/images/shutterstock-13.png";
import Play from "assets/images/goo.png";
import App from "assets/images/app.png";

const DapatkanPendanaan = () => {
  return (
    <pendanaan>
      <div className="container-fluid padding-home-content pl-0 pr-0">
        <div className="row">
          <div className="col-md-6 mb-5">
            <img src={Shutterstock} alt="shutter" className="shutter" />
          </div>
          <div className="col-md-6 d-flex align-items-center px-4">
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="siap-bold-txt">
                  Dapatkan Pendanaan Anda <br /> Sekarang Juga!
                </h2>
              </div>
            </div>
            {/* <div className="row mt-3 justify-content-center">
              <div className="col-12">
                <p className="pendanaan-desc-txt">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  et hendrerit quam. Nunc id volutpat libero.
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <Button className="landing-btn">Daftar Segera</Button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <h5 className="siap-bold-txt text-center">
              Motion Credit dapat diunduh di google play dan app store secara
              gratis
            </h5>
          </div>
        </div>

        <div className="w-100 mt-5 d-flex justify-content-center">
          <img src={Play} alt="google-play" className="pendanaan-apps mr-2" />
          <img src={App} alt="app-store" className="pendanaan-apps" />
        </div>
      </div>
    </pendanaan>
  );
};
export default DapatkanPendanaan;
