import React from "react";
import Icon from "assets/images/search.png";
import { Form, Button } from "react-bootstrap";
import Investasi from "assets/icons/icon-product-investasi@2x.png";
import Dana from "assets/icons/icon-product-dana-tunai@2x.png";
import Mobil from "assets/icons/icon-product-mobil@2x.png";
import SiapHaji from "assets/icons/icon-product-dana-haji@2x.png";
import SiapRumah from "assets/icons/icon-product-rumah@2x.png";
const ProdukKami = () => {
  return (
    <>
      <div className="row mt-5 mb-3">
        <div className="col-12 d-flex justify-content-center">
          <h5 className="siap-bold-txt">Produk Motion Credit</h5>
        </div>
      </div>

      <div className="produk-home-card-container">
        {/* <div className="produk-home-card">
          <img src={Investasi} alt="logo-investasi" />
          <div className="produk-home-txt">Motion Investasi</div>
        </div> */}

        {/* <div className="produk-home-card">
          <img src={Dana} alt="logo-coin" />
          <div className="produk-home-txt">Motion Multiguna</div>
        </div> */}

        <div className="produk-home-card">
          <img src={Mobil} alt="logo-mobil" />
          <div className="produk-home-txt">MNC Dana Mobil</div>
        </div>

        <div className="produk-home-card">
          <img src={SiapHaji} alt="logo-haji" />
          <div className="produk-home-txt">MNC Dana Haji</div>
        </div>

        <div className="produk-home-card">
          <img src={SiapRumah} alt="logo-investasi" />
          <div className="produk-home-txt">MNC Dana Rumah</div>
        </div>
      </div>
    </>
  );
};
export default ProdukKami;
