import React from "react";
import NavBar from "components/NavBar";
import Disclaimer from "components/Disclaimer";
import Footer from "components/FooterContent";
import Tentang from "components/TentangContent";
import TentangSiap from "components/TentangSiap";
import Corporate from "components/CorporateValue";
import Tim from "components/Tim";

const TentangKamiPage = () => {
  return (
    <>
      <NavBar />

      <div className="container">
        <div className="section-space50">
          <Tentang />
          <TentangSiap />
        </div>
      </div>
      <Corporate />
      {/* <div className="container">
        <div className="section-space50">
          <Tim />
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default TentangKamiPage;
