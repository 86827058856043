import React, { createContext, useReducer } from "react";

export const ProjectContext = createContext();

const initialState = {
  isLogin: false,
  user: null,
  loading: true,
  modalLoading: false,
  modalAccount: false,
  modalNotif: false,
  modalTagihan: false,
  tagihan: null,
  loan_period_id: undefined,
  location: undefined,
  locationKTP: undefined,
  locationOffice: undefined,
  locationSpouse: undefined,
  collapsed: true,
  dim: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "USER_LOADED":
      console.log("USER_LOADED");
      //localStorage.setItem("token", action.payload.access_token);
      return {
        ...state,
        isLogin: true,
        user: action.payload,
        loading: false,
      };
    case "SET_COLLAPSED":
      return {
        ...state,
        collapsed: action.payload,
      };
    case "SET_DIM":
      return {
        ...state,
        dim: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        modalLoading: action.payload,
      };
    case "SET_MODAL_ACCOUNT":
      return {
        ...state,
        modalAccount: action.payload,
      };
    case "SET_MODAL_NOTIFICATION":
      return {
        ...state,
        modalNotif: action.payload,
      };
    case "SET_MODAL_TAGIHAN":
      return {
        ...state,
        modalTagihan: action.payload,
      };
    case "SET_TAGIHAN":
      return {
        ...state,
        tagihan: action.payload,
      };
    case "SET_LOCATION":
      return {
        ...state,
        location: action.payload,
      };
    case "SET_LOCATION_KTP":
      return {
        ...state,
        locationKTP: action.payload,
      };
    case "SET_LOCATION_SPOUSE":
      return {
        ...state,
        locationSpouse: action.payload,
      };
    case "SET_LOCATION_OFFICE":
      return {
        ...state,
        locationOffice: action.payload,
      };
    case "SET_LOAN_PERIOD":
      localStorage.setItem("loan_period_id", action.payload);
      return {
        ...state,
      };
    case "AUTH_ERROR":
    case "LOGIN_FAIL":
      console.log("LOGIN_FAIL");
      return {
        ...state,
        isLogin: false,
        user: null,
        loading: false,
      };

    case "LOGIN":
      console.log("LOGIN");
      localStorage.setItem("token", action.payload);

      return {
        ...state,
        isLogin: true,
        loading: false,
      };
    case "LOGOUT":
      console.log("LOGOUT");
      localStorage.removeItem("token");
      localStorage.removeItem("mitra_status");
      localStorage.removeItem("loan_period_id");
      localStorage.removeItem("dana_mobil");
      localStorage.removeItem("simulation_mobil");
      localStorage.removeItem("active_participant");
      localStorage.removeItem("email");
      return {
        ...state,
        isLogin: false,
        loading: false,
        user: null,
      };
    default:
      throw new Error();
  }
};

export const ProjectContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProjectContext.Provider value={[state, dispatch]}>
      {props.children}
    </ProjectContext.Provider>
  );
};
