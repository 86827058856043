import React, { useContext, useState, useEffect } from "react";
import { ProjectContext } from "context/ProjectContext";
import { useLocation } from "react-router-dom";
import ModalCallbackEsign from "components/ModalCallbackEsign";
import { APIPUBLIC } from "config/api";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Component() {
    const [state, dispatch] = useContext(ProjectContext);
    const [modalActive, setModalActive] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    let query = useQuery();

    const msg = document.URL.split("?msg=")[1];
    console.log({ msg })

    const msg2 = query.toString()
    console.log({ msg2 })

    const activeSign = async () => {
      try {
        dispatch({
          type: "SET_LOADING",
          payload: true,
        });
        
        const res = await APIPUBLIC.get(`/callback/digisign/sign?msg=${msg}`);
        setModalActive(true);
        
        if (res.data.success) {             
          setIsSuccess(true);
        } 
        
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        alert(error);
      }
    };

    const handleRedirect = () => {
      window.close();
      window.location.replace("/callback?application_status=signed");
    };

    useEffect(() => { activeSign() }, []);

    return (      
        <>        
          <ModalCallbackEsign show={modalActive}
            handleClose={() => setModalActive(false)}
            route={() => handleRedirect()}
            isSuccess={isSuccess}
          />
          <div className={state.dim ? "dim" : ""} /> 
        </>
    );
};

export default Component;