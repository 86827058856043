import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ProjectContext } from "context/ProjectContext";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { IoMdClose } from "react-icons/io";
import ModalLoading from "./ModalLoading";
import LogoOjk from "assets/images/OJK_Logo.png";
import LogoColor from "assets/images/logo_motion.png";
import MNC from "assets/images/mnc-group.png";

const AsideHome = ({ collapsed, handleCollapsed }) => {
  const [state, dispatch] = useContext(ProjectContext);
  const history = useHistory();
  const location = useLocation();

  const { pathname } = location;

  return (
    <>
      <ModalLoading
        show={state.modalLoading}
        handleClose={() =>
          dispatch({
            type: "SET_LOADING",
            payload: false,
          })
        }
      />
      <ProSidebar
        collapsed={collapsed}
        breakPoint="md"
        collapsedWidth="0px"
        rtl={false}
        className="pro-sidebar-right"
        style={{ right: 0, left: "auto" }}
      >
        <div className="aside-top-header py-2 px-2">
          <img src={LogoOjk} alt="ojk" className="logo-ojk" />
        </div>
        <SidebarHeader>
          <div className="aside-header mb-5">
            <img src={LogoColor} alt="Logo" className="icon-color-mobile-nav" />
          </div>
        </SidebarHeader>

        <SidebarContent>
          <div className="aside-choose-account mt-5"></div>
          <div className="aside-btn-container mt-4">
            <div
              className={`aside-btn-mitra ${pathname === "/" && "active"}`}
              onClick={() => {
                history.push("/");
                handleCollapsed(true);
              }}
            >
              Beranda
            </div>
            <div
              className={`aside-btn-mitra mt-3 ${
                pathname === "/list-produk" && "active"
              }`}
              onClick={() => {
                history.push("/list-produk");
                handleCollapsed(true);
              }}
            >
              Produk
            </div>
            <div
              className={`aside-btn-mitra mt-3 ${
                pathname === "/informasi" && "active"
              }`}
              onClick={() => {
                history.push("/informasi");
                handleCollapsed(true);
              }}
            >
              Informasi
            </div>
            <div
              className={`aside-btn-mitra mt-3 ${
                pathname === "/tentang-kami" && "active"
              }`}
              onClick={() => {
                history.push("/tentang-kami");
                handleCollapsed(true);
              }}
            >
              Tentang Kami
            </div>
          </div>
        </SidebarContent>

        <SidebarFooter>
          <div className="aside-footer-navbar">
            <div>
              <img src={MNC} alt="logo-mnc" />
              <span className="aside-member-txt mr-2">Member OF</span>
            </div>
          </div>
          <div className="aside-footer-navbar mt-1">
            <div className="aside-member-txt">2021 Motion Credit &copy;</div>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </>
  );
};

export default AsideHome;
