import React from "react";
import { useHistory } from "react-router-dom";
import SiapHaji from "assets/icons/icon-product-dana-haji-big.png";
import SiapHaji2 from "assets/icons/icon-product-dana-haji-big@2x.png";
import SiapHaji3 from "assets/icons/icon-product-dana-haji-big@3x.png";

const DanaHaji = () => {
  const router = useHistory();
  return (
    <benefit>
      <div className="section-space50">
        <div className="container">
          <div className="row">
            <div className="col-md-7 d-flex justify-content-center">
              <img
                src={SiapHaji}
                alt="logo-spin"
                srcSet={`${SiapHaji2} 866w, ${SiapHaji3} 1314w`}
                style={{ width: "450px", height: "450px" }}
              />
            </div>

            <div className="col-md-5 d-flex flex-column justify-content-center">
              <div className="row mt-3">
                <div className="col">
                  <h3 className="landing-head-desc">MNC Dana Haji</h3>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className="text-justify">
                    Percepat mimpimu untuk beribadah Haji. Dapatkan pembiayaan
                    pendaftaran porsi Hajimu sekarang dan nikmati kemudahan
                    dalam pengurusan pendaftaran Haji bersama kami. MNC Dana
                    Haji, mudah, pasti, berkah!
                  </p>
                </div>
              </div>
              {/* <div className="row">
                <div className="col">
                  <button
                    className="btn btn-next w-auto"
                    onClick={() => router.push("/produk-haji")}
                  >
                    Selengkapnya
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </benefit>
  );
};
export default DanaHaji;
