import React from "react";


function Component() {

    const status = document.URL.split("?application_status=")[1];
    if (status && status !== "") {
      window.close();
    }
    
    return (
      <h1 className="modal-nopol-header mt-4">
      Aktivasi
      </h1>
    );
};

export default Component;